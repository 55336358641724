const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const isValidNorthAmericanPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const validationMethods = {
  isEmail (email) {
    return isValidEmail.test(email)
  },

  isPhone (phone) {
    return isValidNorthAmericanPhone.test(phone)
  }
}

const ValidationPlugin = {
  install (Vue, options) {
    Vue.prototype.$validation = validationMethods
  }
}

export default ValidationPlugin
