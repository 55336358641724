import Vue from 'vue'

const actions = {
  getCompanyGroupMediaAssets (context, params) {
    const path = `/companies/${params.companyId}/company-groups/${params.companyGroupId}/media-assets`
    return Vue.api.get(path)
  },

  updateMediaAssetIsDeletedStatus (context, data) {
    const path = `/companies/${data.companyId}/company-groups/${data.companyGroupId}/media-assets`
    return Vue.api.post(path, { data })
  }

}

export default actions
