import Vue from 'vue'

const actions = {

  updateMediaAssetTag (context, data) {
    const path = `/companies/${data.companyId}/media-assets/${data.mediaAssetId}/tag`
    return Vue.api.patch(path, { data })
  }
}

export default actions
