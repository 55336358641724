import Vue from 'vue'
import Vuex from 'vuex'
import User from './user'
import Group from './group'
import Client from './client'
import Campaign from './campaign'
import Contact from './contact'
import Agency from './agency'
import Data from './data'
import Admin from './admin'
import Reporting from './reporting'
import Presentation from './presentation'
import Support from './support'
import External from './external'
import Tag from './tag'
import UserNotification from './user-notification'
import Company from './company'
import ExternalOrder from './external-order'
import Dashboard from './dashboard'
import Workspace from './workspace'
import UserTask from './user-task'
import MediaAsset from './media-asset'
import Bookmark from './bookmark'
import RequestForProposal from './request-for-proposal'

import { remove, find, isObject, isNil } from 'lodash-es'

import { app, build } from '@/config'

const { twoFactorAuthenticationEnforcement, isTestMode } = app
const { version } = build

Vue.use(Vuex)

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const generateMessageObj = (value, type) => {
  const key = getRandomIntInclusive(1000000000, 9999999999)
  return { key, value, type }
}

const CONNECTION_ERROR_MESSAGE = 'Oops... you\'re having trouble connecting with Advisr. Please check your internet connection and refresh your browser.'
const OUTDATED_ERROR_MESSAGE = 'The Advisr platform has been updated! You must refresh your browser to continue using the application.'

const NUM_AVAILABLE_AUTH_BACKGROUND_IMAGES = 3

const initialRootState = {
  deeplink: null,
  previewStyles: null,
  unSavedValues: false,
  showUnsavedModal: false,
  toNextRoute: null,
  selectedCompanyForAudience: null,
  selectedProductName: null,
  showTermsOfService: false,
  ioRedirected: false,
  headerBackRoute: null,
  '2faValidationRequired': false,
  '2faEnrollmentRequired': false,
  enableExternalMappings: false,
  authBackgroundImageIndex: isTestMode ? 1 : getRandomIntInclusive(1, NUM_AVAILABLE_AUTH_BACKGROUND_IMAGES),
  currentVersion: version
}

const initialModuleState = {
  User: JSON.parse(JSON.stringify(User.state)),
  Group: JSON.parse(JSON.stringify(Group.state)),
  Client: JSON.parse(JSON.stringify(Client.state)),
  Contact: JSON.parse(JSON.stringify(Contact.state)),
  Campaign: JSON.parse(JSON.stringify(Campaign.state)),
  Agency: JSON.parse(JSON.stringify(Agency.state)),
  Data: JSON.parse(JSON.stringify(Data.state)),
  Admin: JSON.parse(JSON.stringify(Admin.state)),
  External: JSON.parse(JSON.stringify(External.state)),
  Tag: JSON.parse(JSON.stringify(Tag.state)),
  Dashboard: JSON.parse(JSON.stringify(Dashboard.state)),
  UserNotification: JSON.parse(JSON.stringify(UserNotification.state)),
  Workspace: JSON.parse(JSON.stringify(Workspace.state)),
  RequestForProposal: JSON.parse(JSON.stringify(RequestForProposal.state)),
}

const initialState = Object.assign({}, initialRootState, initialModuleState)

export default new Vuex.Store({
  modules: {
    User,
    Group,
    Client,
    Campaign,
    Contact,
    Agency,
    Data,
    Admin,
    Reporting,
    Presentation,
    Support,
    External,
    Tag,
    UserNotification,
    Company,
    ExternalOrder,
    Dashboard,
    Workspace,
    UserTask,
    MediaAsset,
    Bookmark,
    RequestForProposal
  },

  plugins: [
    (store) => {
      Vue.$store = store
    }
  ],

  state: Object.assign({
    isUserAuthenticated: undefined,
    messages: {
      error: [],
      info: [],
      success: []
    }
  }, initialRootState),

  mutations: {
    setDeeplink (state, deeplink) {
      state.deeplink = deeplink
    },

    clearDeeplink (state) {
      state.deeplink = null
    },

    SET_IS_USER_AUTHENTICATED (state, isUserAuthenticated) {
      state.isUserAuthenticated = isUserAuthenticated
      this._vm.$socket.reconnect()
    },

    SET_2FA_VALIDATION_REQUIRED (state, isRequired) {
      state['2faValidationRequired'] = isRequired
    },

    SET_2FA_ENROLLMENT_REQUIRED (state, isRequired) {
      state['2faEnrollmentRequired'] = !twoFactorAuthenticationEnforcement ? false : isRequired
    },

    ADD_ERROR_MESSAGE (state, message) {
      state.messages.error.push(generateMessageObj(message, 'error'))
    },

    ADD_INFO_MESSAGE (state, message) {
      state.messages.info.push(generateMessageObj(message, 'info'))
    },

    ADD_SUCCESS_MESSAGE (state, message) {
      state.messages.success.push(generateMessageObj(message, 'success'))
    },

    ADD_CONNECTION_ERROR_MESSAGE (state) {
      // Don't show connection error if already shown
      if (!find(state.messages.error, { value: CONNECTION_ERROR_MESSAGE })) {
        state.messages.error.push(generateMessageObj(CONNECTION_ERROR_MESSAGE, 'error'))
      }
    },

    ADD_OUTDATED_ERROR_MESSAGE (state) {
      // Don't show outdated error if already shown
      if (!find(state.messages.error, { value: OUTDATED_ERROR_MESSAGE })) {
        state.messages.error.push(generateMessageObj(OUTDATED_ERROR_MESSAGE, 'error'))
      }
    },

    DISMISS_MESSAGE (state, message) {
      remove(state.messages[message.type], msg => msg.key === message.key)
      if (message.type === 'error') state.messages.error = [...state.messages.error]
      if (message.type === 'info') state.messages.info = [...state.messages.info]
      if (message.type === 'success') state.messages.success = [...state.messages.success]
    },

    RESET_STATE (state) {
      Object.keys(state).forEach(key => {
        if (isObject(initialState[key])) Object.assign(state[key], JSON.parse(JSON.stringify(initialState[key])))
        else if (initialState[key] !== undefined) state[key] = JSON.parse(JSON.stringify(initialState[key]))
      })
    },

    SET_PREVIEW_STYLES (state, styles) {
      state.previewStyles = styles
    },

    SET_UNSAVED_VALUE (state, isSaved) {
      state.unSavedValue = isSaved
    },

    SET_UNSAVED_MODAL (state, showModal) {
      state.showUnsavedModal = showModal
    },

    SET_SELECTED_COMPANY_FOR_AUDIENCE (state, companyId) {
      state.selectedCompanyForAudience = companyId
    },

    SET_SELECTED_PRODUCT_NAME (state, productName) {
      state.selectedProductName = productName
    },

    SET_SHOW_TERMS_OF_SERVICE (state, show) {
      state.showTermsOfService = show
    },

    SET_IO_REDIRECTED (state, isRedirected) {
      state.ioRedirected = isRedirected
    },

    SET_HEADER_BACK_ROUTE (state, headerBackRoute) { // overrides the route used in header's back arrow button
      state.headerBackRoute = headerBackRoute
    },

    SET_ENABLE_EXTERNAL_MAPPINGS (state, enableExternalMappings) {
      state.enableExternalMappings = enableExternalMappings
    },

    SET_CURRENT_VERSION (state, currentVersion) {
      state.currentVersion = currentVersion
    }
  },

  actions: {
    getCurrentVersion (context) {
      return Vue.http
        .get('/version.json')
        .then(response => {
          const currentVersion = response?.body?.build
          if (!isNil(currentVersion)) {
            context.commit('SET_CURRENT_VERSION', currentVersion)
          }
        })
    }
  },

  getters: {
    getOverrideStyles (state) {
      const { company } = state.Group

      const styles = (state.previewStyles)
        ? state.previewStyles
        : (company && company.styles) ? company.styles : []

      return styles.reduce((overrideStyles, style) => {
        overrideStyles[style.name] = { name: style.name, value: style.value, isVariable: style.isVariable }
        return overrideStyles
      }, {})
    }
  }
})
