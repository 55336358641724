import MediaAsset from './mediaAsset'
import CompanyGroupMediaAsset from './companyGroupMediaAsset'
import MediaAssetComment from './mediaAssetComment'
import MediaAssetTag from './mediaAssetTag'

export default Object.assign(
  {},
  MediaAsset,
  CompanyGroupMediaAsset,
  MediaAssetComment,
  MediaAssetTag
)
