<template>
  <div
    :style="headerStyle"
    class="header"
  >
    <div class="header-content d-flex flex-row">
      <icon-link
        v-if="headerBackRoute"
        :to="headerBackRoute"
        icon="Back"
        w="24"
        h="24"
        title=" "
        class="back-link"
        datatest="back-link"
        @router-click="clickedHeaderBackButtonArrow"
      />

      <h4
        class="d-flex align-items-center"
        data-test="header-text"
      >
        {{ headerTextFormatted }}
      </h4>

      <div
        v-if="!hideHeaderRight"
        class="d-flex header-right"
      >
        <div
          v-if="headerLogo === 'client'"
          class="logos d-flex flex-row align-items-center"
        >
          <div
            v-if="clientLogoURL"
            class="logo"
          >
            <img
              :src="clientLogoURL"
              data-test="app-header-client-logo"
            >
          </div>
        </div>

        <div
          v-else
          class="logos d-flex flex-row align-items-center"
        >
          <div
            v-if="companyLogoURL"
            class="logo"
          >
            <img :src="companyLogoURL">
          </div>

          <div
            v-if="groupLogoURL"
            class="logo"
          >
            <img :src="groupLogoURL">
          </div>
        </div>
        <div class="separator" />
        <div
          v-if="permissions.hasGlobalChatbot"
          class="d-flex align-items-center justify-content-center notification-bell ml-4"
          @click="shouldShowChatbot = true"
        >
          <chatbot-icon
            w="36"
            h="36"
          />
        </div>

        <div
          class="d-flex align-items-center justify-content-center header-icon ml-4"
          @click="showNotificationsPane"
        >
          <notifications-icon
            v-if="shouldShowNotificationsPane || shouldShowTasksPane"
            w="36"
            h="36"
            :status="notificationsIconStatus"
          />
          <a-tooltip
            v-else
            position="bottom"
          >
            <template #tip>
              Notifications
            </template>
            <template #hover-item>
              <notifications-icon
                w="36"
                h="36"
                :status="notificationsIconStatus"
              />
            </template>
          </a-tooltip>
        </div>

        <div
          class="d-flex align-items-center justify-content-center header-icon ml-4"
          @click="showTasksPane"
        >
          <tasks-icon
            v-if="shouldShowTasksPane || shouldShowNotificationsPane"
            w="36"
            h="36"
            :status="tasksIconStatus"
          />
          <a-tooltip
            v-else
            position="bottom"
          >
            <template #tip>
              Tasks
            </template>
            <template #hover-item>
              <tasks-icon
                w="36"
                h="36"
                :status="tasksIconStatus"
              />
            </template>
          </a-tooltip>
        </div>

        <div
          ref="quickCreateToggle"
          class="d-flex align-items-center justify-content-center header-icon ml-4"
          @click="toggleQuickCreate"
        >
          <quick-create-icon
            v-if="shouldShowQuickCreate || shouldShowTasksPane || shouldShowNotificationsPane"
            w="36"
            h="36"
            :status="quickCreateIconStatus"
          />
          <a-tooltip
            v-else
            position="bottom"
          >
            <template #tip>
              Create
            </template>
            <template #hover-item>
              <quick-create-icon
                w="36"
                h="36"
                :status="quickCreateIconStatus"
              />
            </template>
          </a-tooltip>
        </div>

        <div
          ref="userMenuToggle"
          class="d-flex flex-row align-items-center user-profile"
          data-test="user-profile-menu"
          @click="toggleUserMenu"
        >
          <div
            :style="userAvatarStyles"
            class="d-flex align-items-center justify-content-center user-avatar"
          >
            <camera-icon
              v-if="!userAvatarURL"
              w="15"
              h="15"
            />
          </div>
          <div class="user-down-arrow" />
        </div>
      </div>
    </div>

    <transition name="header-transition">
      <div
        v-if="showUserMenu"
        ref="userMenu"
        class="d-flex flex-column align-items-center justify-content-center dropdown-menu"
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="toggleUserMenu"
        >
          <span aria-hidden="true">
            &times;
          </span>
        </button>

        <div
          :style="userAvatarStyles"
          class="d-flex align-items-center justify-content-center dropdown-menu-avatar"
        >
          <camera-icon
            v-if="!userAvatarURL"
            w="32"
            h="32"
          />
        </div>

        <div class="user-name">
          {{ user.firstName }} {{ user.lastName }}
        </div>

        <i v-if="user.isSpoofed">
          Spoofed -
          <a-link
            @click="unspoofUser"
          >
            Login original user
          </a-link>
        </i>

        <span
          v-if="user.title"
          class="title"
        >
          {{ user.title }}
        </span>

        <badge
          variant="info"
          class="user-group-badge"
        >
          {{ userGroupName }}
        </badge>

        <a-link
          class="edit-profile-link"
          datatest="edit-profile-button"
          :show-underline="true"
          @click="editProfile"
        >
          Edit Profile
        </a-link>

        <hr class="w-100">

        <div class="buttons w-100">
          <a-button
            v-if="user.companyGroups.length > 1"
            :to="{ name: 'SwitchGroup', query: { path: $route.path } }"
            variant="bordered"
            size="small"
            class="switch-group-button w-100 d-flex flex-row justify-content-center align-items-center"
          >
            <switch-icon
              w="16"
              h="18"
              title="Switch Organization"
              class="icon"
            />

            Switch Organization
          </a-button>

          <a-button
            :to="{ name: 'Logout' }"
            variant="bordered"
            size="small"
            class="w-100 d-flex flex-row justify-content-center align-items-center"
          >
            <logout-icon
              w="18"
              h="18"
              title="Log Out"
              class="icon"
            />

            Log Out
          </a-button>
        </div>
      </div>
    </transition>

    <transition name="header-transition">
      <div
        v-if="shouldShowQuickCreate"
        ref="quickCreateMenu"
        class="d-flex flex-column dropdown-menu quickcreate-menu"
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="toggleQuickCreate"
        >
          <span aria-hidden="true">
            &times;
          </span>
        </button>
        <span class="header-title font-semibold ml-3">Create</span>
        <hr class="w-100">

        <a-link
          v-if="permissions.canCreateQuickOrder"
          class="quick-link ml-3 font-medium d-flex items-center my-2"
          @click="createQuickOrder"
        >
          <quick-order-icon
            h="24"
            w="24"
            class="mr-1"
          /> Quick Order
        </a-link>

        <a-link
          class="quick-link ml-3 font-medium d-flex items-center my-2"
          @click="createCampaign"
        >
          <campaigns-icon
            h="24"
            w="24"
            class="mr-2"
          /> Campaign
        </a-link>

        <add-client @close="toggleQuickCreate" />

        <a-link
          v-if="canCreateContactForCompany || canCreateContactForGroup"
          class="quick-link ml-3 font-medium d-flex items-center my-2"
          @click="createContact"
        >
          <contacts-icon
            h="24"
            w="24"
            class="mr-2"
          /> Contact
        </a-link>
      </div>
    </transition>

    <notifications-pane
      ref="notificationPane"
      :show="shouldShowNotificationsPane"
      :user-id="user.id"
      @user-task="goToUserTask"
      @task-notification="taskNotification"
      @close="hideNotificationsPane"
    />
    <tasks-pane
      ref="taskPane"
      :show="shouldShowTasksPane"
      @close="hideTasksPane"
    />
    <chatbot
      v-show="shouldShowChatbot"
      @close="shouldShowChatbot = false"
    />
  </div>
</template>

<script>
import { findLast } from 'lodash-es'
import {
  CameraIcon,
  CampaignsIcon,
  ChatbotIcon,
  ContactsIcon,
  LogoutIcon,
  NotificationsIcon,
  QuickCreateIcon,
  QuickOrderIcon,
  SwitchIcon,
  TasksIcon
} from '@/components/icons'
import NotificationsPane from '../notifications/NotificationsPane.vue'
import TasksPane from '../tasks/TasksPane.vue'
import Chatbot from '@/components/support/Chatbot.vue'
import AddClient from '@/components/layout/components/AddClient.vue'

import { mapActions, mapMutations } from 'vuex'

const EMIT_NOTIFICATION_EVENT = 'user-notification'

export default {
  components: {
    AddClient,
    CameraIcon,
    CampaignsIcon,
    Chatbot,
    ChatbotIcon,
    ContactsIcon,
    LogoutIcon,
    NotificationsIcon,
    NotificationsPane,
    QuickOrderIcon,
    QuickCreateIcon,
    SwitchIcon,
    TasksIcon,
    TasksPane
  },

  props: {
    headerText: {
      type: String,
      default: undefined
    },

    hideNav: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      showUserMenu: false,
      fromRoute: null,
      toRoute: null,
      shouldShowNotificationsPane: false,
      shouldShowTasksPane: false,
      checkInterval: 10000,
      hasNotifications: null,
      shouldShowChatbot: false,
      shouldShowQuickCreate: false
    }
  },

  computed: {
    hideHeaderRight () {
      return !!this.lastRouteFor('hideHeaderRight')
    },

    companyLogoURL () {
      return this.$store.getters.getCompanyLogoURL
    },

    companyName () {
      return this.$store.state.Admin.company?.name
    },

    groupName () {
      return this.$store.state.Admin.group?.name
    },

    groupLogoURL () {
      return this.$store.getters.getGroupLogoURL
    },

    client () {
      return this.$store.state.Client.client
    },

    clientLogoURL () {
      return this.$store.getters.getClientLogoURL
    },

    user () {
      return this.$store.state.User.user
    },

    userGroupName () {
      return this.user.companyGroup.name
    },

    userAvatarURL () {
      return this.$store.getters.getUserAvatarURL
    },

    userAvatarStyles () {
      const styles = {}
      if (this.userAvatarURL) styles.backgroundImage = 'url(\'' + this.userAvatarURL + '\')'
      return styles
    },

    campaign () {
      return this.$store.state.Campaign.campaign
    },

    deal () {
      return this.$store.state.Deal.deal
    },

    selectedProductName () {
      return this.$store.state.selectedProductName
    },

    templateName () {
      return this.$store.state.Presentation.templateName
    },

    slideName () {
      return this.$store.state.Presentation.slideName
    },

    datasetGroupName () {
      return this.$store.state.Admin.datasetGroup?.name || ''
    },

    crmName () {
      return this.$store.state.External.crmName || ''
    },

    requestForProposalName () {
      return this.$store.state.RequestForProposal.requestForProposal.name || ''
    },

    headerTextFormatted: {
      get () {
        switch (this.headerText) {
          case '{{CLIENT}}':
            return (this.client) ? this.client.name : ''
          case '{{CAMPAIGN}}':
            return (this.campaign) ? this.campaign.name : ''
          case '{{COMPANY}}':
            return (this.companyName) ? this.companyName : ''
          case '{{GROUP}}':
            return (this.groupName) ? this.groupName : ''
          case '{{PRODUCT}}':
            return this.selectedProductName || ''
          case '{{TEMPLATE}}':
            return this.templateName || ''
          case '{{SLIDE}}':
            return this.slideName || ''
          case '{{CRM}}':
            return this.crmName || ''
          case '{{LAYOUT}}':
            return this.slideName || ''
          case '{{DATASETGROUP}}':
            return this.datasetGroupName || ''
          case '{{DEAL}}':
            return this.deal?.name || ''
          case '{{RFP}}':
            return this.requestForProposalName || ''
          default:
            return this.headerText
        }
      }
    },

    headerLogo () {
      return this.lastRouteFor('headerLogo')?.meta?.headerLogo || 'group'
    },

    headerBackRoute () {
      if (this.$store.state.headerBackRoute) { // use overriding back arrow button route
        return this.$store.state.headerBackRoute
      }

      const route = this.lastRouteFor('headerBackRoute')

      if (route) {
        const routeName = route.meta.headerBackRoute
        if (routeName === 'ClientMyCampaigns') {
          if (this.client.id) {
            return {
              name: routeName,
              params: { id: this.client.id }
            }
          }
          return null
        } else if (routeName === 'CampaignSummary') {
          if (this.campaign.id) {
            return {
              name: routeName,
              params: { id: this.campaign.id }
            }
          }
          return null
        } else if (routeName === 'AdminCollateralAndDocuments') {
          return {
            name: routeName,
            params: { id: this.$route.params.id }
          }
        }
        return {
          name: routeName
        }
      }
      return null
    },

    headerStyle () {
      return { left: (this.hideNav) ? '0px' : 'var(--app-nav-width)' }
    },

    notificationsIconStatus () {
      if (this.shouldShowNotificationsPane) {
        return 'inactive'
      } else if (this.hasNotifications) {
        return 'new'
      } else {
        return 'active'
      }
    },

    tasksIconStatus () {
      return this.shouldShowTasksPane ? 'inactive' : 'active'
    },

    quickCreateIconStatus () {
      return this.shouldShowQuickCreate ? 'active' : 'inactive'
    },

    companyId: {
      get () {
        return this.user.companyGroup.company.id
      }
    },

    groupId: {
      get () {
        return this.user.companyGroup.id
      }
    },

    selectedCompanyId () {
      return this.$store.state.Workspace.filters.companyId ||
             this.$store.state.User.user.companyGroup.companyId
    },

    selectedCompanyGroupId () {
      return this.$store.state.Workspace.filters.companyGroupId
    },
    permissions () {
      return {
        contactCreateForAll: this.$permission.hasPermission('ContactCreateForAll'),
        contactCreateForOwnCompany: this.$permission.hasPermission('ContactCreateForOwnCompany'),
        contactCreateForOwnGroup: this.$permission.hasPermission('ContactCreateForOwnGroup'),
        hasGlobalChatbot: this.$permission.hasPermission('GlobalChatbot'),
        canCreateQuickOrder: false
      }
    },

    currentUserCompanyGroup: {
      get () {
        return this.currentUser.companyGroup
      }
    },

    currentUserCompany: {
      get () {
        return this.currentUserCompanyGroup.company
      }
    },

    currentUser: {
      get () {
        return this.$store.state.User.user
      }
    },

    canCreateContactForCompany () {
      return this.permissions.contactCreateForAll ||
             this.permissions.contactCreateForOwnCompany
    },

    canCreateContactForGroup () {
      return this.permissions.contactCreateForAll ||
             this.permissions.contactCreateForOwnGroup
    },
  },

  watch: {
    $route: {
      handler (to, from) {
        this.fromRoute = from
        this.toRoute = to

        if (['CampaignSummary'].includes(this.toRoute.name)) {
          this.setHeaderBackRoute(null)
        }

        if (to?.query?.taskId) {
          this.showTasksPaneAndGoToTask(to.query.taskId)
        }
      },
      deep: true
    }
  },

  async created () {
    this.checkUserNotifications()
    this.$globalEvent.addEventListener(EMIT_NOTIFICATION_EVENT, this.onSocketEventNotification)

    this.$bus.$on('deeplink:user-notifications', this.naviateToUserNotification)
    this.$bus.$on('deeplink:user-tasks', this.navigateToUserTask)
  },

  beforeDestroy () {
    window.removeEventListener('mousedown', this.onMouseDown)
    this.$globalEvent.removeEventListener(EMIT_NOTIFICATION_EVENT)
    this.$bus.$off('deeplink:user-notifications')
    this.$bus.$off('deeplink:user-tasks')
  },

  methods: {
    onSocketEventNotification (msg) {
      this.checkUserNotifications()
    },

    naviateToUserNotification ({ id }) {
      this.showNotificationsPane()
      this.hideTasksPane()
      setTimeout(() => {
        this.$refs.notificationPane.scrollToElement(id)
      }, 500)
    },

    navigateToUserTask ({ id }) {
      this.goToUserTask(id)
    },

    goToUserTask (userTaskId) {
      this.hideNotificationsPane()
      this.shouldShowTasksPane = true
      setTimeout(() => {
        this.$refs.taskPane.scrollToTask(userTaskId)
      }, 500)
    },

    lastRouteFor (property) {
      return findLast(this.$route.matched, route => { return route.meta[property] })
    },

    clickedHeaderBackButtonArrow () {
      if (this.fromRoute) {
        if (['NewClient', 'NewClientSearch', 'NewClientProfile', 'NewClientLocation', 'NewCampaign'].includes(this.fromRoute.name)) {
          this.setHeaderBackRoute(null) // remove the overriding back arrow button route
        }
      }
    },

    toggleUserMenu () {
      this.shouldShowNotificationsPane = false
      this.shouldShowQuickCreate = false
      this.shouldShowTasksPane = false

      this.showUserMenu = !this.showUserMenu

      if (this.showUserMenu) window.addEventListener('mousedown', this.onMouseDown)
      else window.removeEventListener('mousedown', this.onMouseDown)
    },

    async showNotificationsPane () {
      this.shouldShowQuickCreate = false
      this.hideTasksPane()
      if (this.hasNotifications) {
        await this.clearUnreadUserNotifications({ id: this.user.id })
      }
      this.shouldShowNotificationsPane = true
    },

    hideNotificationsPane () {
      this.hasNotifications = false
      this.shouldShowNotificationsPane = false
    },

    taskNotification () {
      this.$refs.taskPane.loadUserTasks()
    },

    async showTasksPane () {
      this.shouldShowNotificationsPane = false
      this.shouldShowQuickCreate = false
      this.shouldShowTasksPane = true
    },

    async showTasksPaneAndGoToTask (taskId) {
      this.shouldShowNotificationsPane = false
      this.shouldShowQuickCreate = false
      this.shouldShowTasksPane = true
      await this.$refs.taskPane.scrollToTask(taskId)
    },

    async hideTasksPane () {
      this.shouldShowTasksPane = false
    },

    async checkUserNotifications () {
      if (this.user.id) {
        this.hasNotifications = await this.getUnreadUserNotificationsCount(this.user.id).then(count => count > 0)
        if (this.hasNotifications) this.$refs.notificationPane.loadUserNotifications()
      }
    },

    editProfile () {
      this.showUserMenu = false
      this.$router.push({ name: 'Profile' })
    },

    onMouseDown (e) {
      if (
        this.showUserMenu && !(
          e.target === this.$refs.userMenu || this.$refs.userMenu.contains(e.target) ||
          e.target === this.$refs.userMenuToggle || this.$refs.userMenuToggle.contains(e.target)
        )
      ) {
        this.toggleUserMenu()
      }
      if (
        this.shouldShowQuickCreate && !(
          e.target === this.$refs.quickCreateMenu || this.$refs.quickCreateMenu.contains(e.target) ||
          e.target === this.$refs.quickCreateToggle || this.$refs.quickCreateToggle.contains(e.target)
        )
      ) {
        this.toggleQuickCreate()
      }
    },

    async unspoofUser () {
      await this.adminUnspoofUser().then(() => {
        this.$messaging.addInfoMessage(`You are now functioning as your original user ${this.user.firstName} ${this.user.lastName}`)
      }).catch((error) => {
        this.$messaging.addErrorMessage(`Couldn't unspoof user: ${error.message}`)
      })
    },

    toggleQuickCreate () {
      this.shouldShowNotificationsPane = false
      this.shouldShowTasksPane = false

      this.shouldShowQuickCreate = !this.shouldShowQuickCreate

      if (this.shouldShowQuickCreate) window.addEventListener('mousedown', this.onMouseDown)
      else window.removeEventListener('mousedown', this.onMouseDown)
    },

    createCampaign () {
      const route = {
        name: 'NewCampaign',
        query: {
          companyId: this.companyId,
          groupId: this.groupId
        }
      }
      this.toggleQuickCreate()
      if (this.$route.name !== route.name) {
        this.$router.push(route)
      }
    },

    createContact () {
      const route = {
        name: 'WorkspaceMyContacts',
        query: {
          quickCreate: true
        }
      }
      this.toggleQuickCreate()
      this.$router.push(route)
    },

    createQuickOrder () {
      return 'Quick Order Created'
    },

    ...mapActions([
      'adminUnspoofUser',
      'getUnreadUserNotificationsCount',
      'clearUnreadUserNotifications'
    ]),

    ...mapMutations({
      setHeaderBackRoute: 'SET_HEADER_BACK_ROUTE'
    })
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.header-content {
  position: relative;
  height: var(--app-header-height);
  background-color: var(--header-background-color);
  border-bottom: 1px solid var(--border-color);
  padding: 0px 30px 0px 0px;
  z-index: 1;
}

.back-link {
  height: var(--app-header-height);
  width: 70px;
  border-right: 1px solid var(--border-color);
}

.back-link :deep(svg) {
  margin-left: 23px;
  margin-top: 18px;
}

h4 {
  margin-left: 30px;
}

.header-right {
  margin-left: auto;
}

.logo {
  max-width: 150px;
  width: auto;
  height: 36px;
  margin-left: 30px;
  float: right;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.user-profile {
  margin-left: 50px;
  cursor: pointer;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--user-avatar-background-color);
  border: 1px solid var(--user-avatar-border-color);
  fill: var(--user-avatar-color);
  background-size: cover;
  background-position: 50% 50%;
}

.user-down-arrow {
  margin-left: 12px;
  width: 19px;
  height: 6px;
  background-image: url("/images/carat_down.png");
  background-repeat: no-repeat;
}

.dropdown-menu {
  position: absolute;
  z-index: 0;
  top: calc(var(--app-header-height) + 5px);
  right: 15px;
  background-color: var(--header-background-color);
  width: 250px;
  padding: 10px 0px;
  border: 1px solid var(--border-color);
  border-radius: var(--user-profile-border-radius);
}

.dropdown-menu.quickcreate-menu {
  right: 150px;
  width: 200px;
}

.dropdown-menu .header-title {
  line-height: 7px;
}

.dropdown-menu .close {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 7px;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 24px;
  font-weight: 300;
  cursor: pointer;
}

.dropdown-menu-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--user-avatar-background-color);
  border: 1px solid var(--user-avatar-border-color);
  fill: var(--user-avatar-color);
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 10px;
}

.dropdown-menu .user-name {
  font-size: 18px;
  line-height: 27px;
}

.dropdown-menu .title {
  line-height: 23px;
}

.dropdown-menu .user-group-badge {
  margin-top: 10px !important;
}

.dropdown-menu .edit-profile-link {
  margin-top: 10px;
}

.dropdown-menu hr {
  border-width: 1px 0 0 0;
  border-color: var(--border-color);
  border-style: solid;
  margin: 10px 0;
}

.dropdown-menu .buttons {
  padding: 0 10px;
}

.dropdown-menu .buttons .icon {
  margin-right: 6px;
}

.switch-group-button {
  margin-bottom: 10px;
}
.separator {
  border-left: 1px solid #ccc;
  height: 70%;
  margin-left: 10px;
  margin-top: 10px;
}
.header-icon {
  cursor: pointer;
}

</style>
