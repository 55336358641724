import Vue from 'vue'

const actions = {
  downloadMediaAsset (context, params) {
    const path = `/companies/${params.companyId}/media-assets/${params.mediaAssetId}`
    return Vue.api.get(path)
  },

  linkMediaAsset (context, data) {
    const path = `/companies/${data.companyId}/media-assets`
    return Vue.api.post(path, { data })
  },

  uploadMediaAsset (context, data) {
    const companyId = data.get('companyId')

    const path = `/companies/${companyId}/media-assets`
    const headers = { 'Content-Type': 'multipart/form-data' }

    return Vue.api.post(path, { data, headers })
  },

  updateMediaAsset (context, data) {
    const path = `/companies/${data.companyId}/media-assets/${data.mediaAssetId}`
    return Vue.api.patch(path, { data })
  }

}

export default actions
