// Global components not lazy loaded
import AButton from '@advisr/frontend-ui-components/src/components/AButton'
import ALabel from './ALabel'
import AInput from './AInput'
import ALink from '@advisr/frontend-ui-components/src/components/ALink'
import BreadCrumbs from '@advisr/frontend-ui-components/src/components/BreadCrumbs.vue'
import ASelect from './ASelect'
import ASelectFiltered from './ASelectFiltered'
import ASelectAgency from './ASelectAgency'
import NewVersionChecker from './NewVersionChecker'
import Messaging from './Messaging'
import IconLink from './IconLink'
import ActionButton from './ActionButton'
import Badge from './Badge'
import StatusBadge from './StatusBadge'
import LoadingSpinner from './LoadingSpinner'
import Sparkle from './Sparkle'
import HighlightQuery from './HighlightQuery'

export default {
  // Global components included in app chunk
  AButton,
  ActionButton,
  AInput,
  ALabel,
  ALink,
  ASelect,
  ASelectAgency,
  ASelectFiltered,
  Badge,
  BreadCrumbs,
  HighlightQuery,
  IconLink,
  LoadingSpinner,
  Messaging,
  NewVersionChecker,
  Sparkle,
  StatusBadge,

  // Lazy loaded global components
  'rich-text-input': () => import('@advisr/frontend-ui-components/src/components/RichTextInput'),
  'media-gallery': () => import('@advisr/frontend-ui-components/src/components/MediaGallery'),
  modal: () => import('@advisr/frontend-ui-components/src/components/Modal'),
  'a-dialog': () => import('@advisr/frontend-ui-components/src/components/ADialog.vue'),
  'a-dialog-input': () => import('@advisr/frontend-ui-components/src/components/DialogInput.vue'),
  'a-table': () => import('./ATable'),
  'a-gallery': () => import('./AGallery'),
  'a-single-select': () => import('./ASingleSelect'),
  pagination: () => import('./Pagination'),
  'search-input': () => import('./SearchInput'),
  checkbox: () => import('./Checkbox'),
  'checkbox-group': () => import('./CheckboxGroup'),
  'currency-input': () => import('./CurrencyInput'),
  'percent-input': () => import('./PercentInput'),
  'phone-input': () => import('./PhoneInput'),
  'overlay-pane': () => import('./OverlayPane'),
  'overlay-pane-new': () => import('./OverlayPaneNew'),
  'edit-button': () => import('./EditButton'),
  card: () => import('./Card'),
  'date-picker': () => import('./DatePicker'),
  'invalid-input': () => import('./InvalidInput'),
  collapse: () => import('./Collapse'),
  'type-ahead': () => import('./TypeAhead'),
  'toggle-buttons': () => import('./ToggleButtons'),
  'toggle-switch': () => import('./ToggleSwitch'),
  'location-search-input': () => import('./LocationSearchInput'),
  'flow-progress-indicator': () => import('./FlowProgressIndicator'),
  'page-spinner': () => import('./PageSpinner'),
  'button-spinner': () => import('./ButtonSpinner'),
  'generic-spinner': () => import('./GenericSpinner'),
  'circle-image': () => import('./CircleImage'),
  'a-textarea': () => import('./ATextarea'),
  'radio-group': () => import('./RadioGroup'),
  'integer-input': () => import('./IntegerInput'),
  'float-input': () => import('./FloatInput'),
  'item-deletion-icon': () => import('@advisr/frontend-ui-components/src/components/ItemDeletionIcon'),
  'range-input': () => import('./RangeInput'),
  'multiple-select-columns': () => import('./MultipleSelectColumns'),
  'email-input': () => import('./EmailInput'),
  'color-picker': () => import('./ColorPicker'),
  accordion: () => import('./Accordion'),
  'multi-select-overlay': () => import('./MultiSelectOverlay'),
  'multi-select-pill': () => import('./multi_select_pill/MultiSelectPill'),
  'multi-select-avatar': () => import('./multi_select_pill/MultiSelectAvatar'),
  'new-multi-select-avatar': () => import('./multi_select_pill/NewMultiSelectAvatar'),
  'select-pill': () => import('./multi_select_pill/SelectPill'),
  'add-item': () => import('./multi_select_pill/AddItem'),
  'image-upload': () => import('./ImageUpload'),
  'button-drop-down': () => import('@advisr/frontend-ui-components/src/components/ButtonDropDown'),
  'button-select': () => import('@advisr/frontend-ui-components/src/components/ButtonSelect'),
  'filter-menu': () => import('@advisr/frontend-ui-components/src/components/FilterMenu.vue'),
  'tree-list': () => import('./TreeList'),
  'vertical-tab-container': () => import('./VerticalTabContainer'),
  'a-color-picker': () => import('@advisr/frontend-ui-components/src/components/colorPicker/ColorPicker'),
  checkboard: () => import('@advisr/frontend-ui-components/src/components/colorPicker/common/Checkboard'),
  'terms-of-service-modal': () => import('./TermsOfServiceModal'),
  'a-select-opt-groups': () => import('./ASelectOptGroups'),
  'info-notification': () => import('./InfoNotification'),
  'success-alert': () => import('./SuccessAlert'),
  'pending-alert': () => import('./PendingAlert'),
  'submission-failed-alert': () => import('./SubmissionFailedAlert'),
  walkme: () => import('./WalkMe.vue'),
  'a-tooltip': () => import('./ATooltip'),
  'user-avatar': () => import('./UserAvatar.vue'),
  'avatar-tooltip': () => import('./AvatarTooltip.vue'),
  'owner-multi-select-pill': () => import('./multi_select_pill/OwnerMultiSelectPill.vue'),
  'team-multi-select-pill': () => import('./multi_select_pill/TeamMultiSelectPill.vue'),
  'search-gallery': () => import('./SearchGallery'),
  'file-uploader': () => import('@advisr/frontend-ui-components/src/components/FileUpload'),
  'file-upload': () => import('./file_uploads/FileUpload'),
  'file-upload-progress': () => import('./file_uploads/FileUploadProgress'),
  'file-drop-overlay': () => import('./file_uploads/FileDropOverlay'),
  'multi-select-product': () => import('./multi_select_pill/MultiSelectProduct'),
  'actions-drop-down': () => import('@advisr/frontend-ui-components/src/components/ActionsDropDown.vue'),
  'table-action-menu': () => import('./TableActionMenu.vue'),
  'page-navigation-tabs': () => import('./PageNavigationTabs.vue'),
  pill: () => import('./Pill.vue')
}
