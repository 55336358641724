import Vue from 'vue'

const actions = {

  listMediaAssetComments (context, data) {
    const path = `/companies/${data.companyId}/media-assets/${data.mediaAssetId}/comments`
    return Vue.api.get(path, { data })
  },

  createMediaAssetComment (context, data) {
    const path = `/companies/${data.companyId}/media-assets/${data.mediaAssetId}/comments`
    return Vue.api.post(path, { data })
  },

  deleteMediaAssetComment (context, data) {
    const path = `/companies/${data.companyId}/media-assets/${data.mediaAssetId}/comments/${data.id}`
    return Vue.api.delete(path, { data })
  }
}

export default actions
